<template>
  <div class="user">
    <!--<div class="photo">
       <img v-if="userInfo.avatar" :src="userInfo.avatar"/>
      <img v-else :src="'../../../static/img/faces/face-0.jpg'" width="10"/> 
    </div>-->
    <div class="info">
<el-dropdown  trigger="click" split-button type="info">
  <span class="el-dropdown-link">
    Hi {{user.firstname}}
  </span>
  <template #dropdown>
    <el-dropdown-menu>
 
      <el-dropdown-item> <a href="javascript:void(0)" @click="logout">
                <span class="sidebar-normal">Logout</span>
              </a></el-dropdown-item>
   
    </el-dropdown-menu>
  </template>
</el-dropdown>

    
     
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const closed = ref(true);
    const toggleMenu = function () {

      closed.value = !closed.value;
    };

    const logout = function () {
      console.log("LOGGING OUT");
      store.dispatch("auth/logout").then(() => {
        router.push("/login");
      });
    };
    return {
      closed,
      logout,
      toggleMenu,
      user: store.state.auth.loggedInUser,
    };
  },
};
</script>
<style>
  .el-dropdown-link {
    margin-left: 10px;
    cursor: pointer;
    color: #FFF;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
.collapsed {
  transition: opacity 1s;
}
.user {
  z-index: 6;
  margin-left:30px;
}
</style>
