<template>
  <el-card class="box-card" shadow="hover">
    <template #header>
      <div class="card-header">
        <h3>Ads Cost v Revenue</h3>
        <span class="text-muted">By Month Source:GA (Stripe & paypal)</span>
        <span class="pull-right"> <el-button @click="toggleDisplay">view <span v-if="!showGraph">graph</span><span v-if="showGraph">table</span></el-button></span>
      </div>
    </template>
    <apexchart
      v-if="!loading && showGraph"
     
      id="paid"
      type="area"
      :options="options"
      :series="series"
    ></apexchart>
        <data-table v-if="!loading && !showGraph" :data="paid" class="sm" 
                :total="total"
                ref="table"
                :columns="columns"
                :showEdit="false"
                v-on:pageSizeChanged="pageSizeChanged"
                v-on:pageCurrentChanged="pageCurrentChanged"
              >
          
    </data-table>
  </el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import Paid from "../graphql/ga_paid_month.query.gql";
import DataTable from "../components/UI/DataTable";
import { reactive,ref } from "vue";
import moment from "moment";
export default {
  name: "Users",
  components: {DataTable},
  setup() {
    const variables = reactive({
      limit: 80,
      offset: 12,
    });
const showGraph=ref(true)
    const { result, loading } = useQuery(Paid, variables);
function toggleDisplay(){
  showGraph.value=!showGraph.value
}
    //  const paid= useResult(result,null,data => data.ga_paid_revenue)
    // const total = useResult(result,null,data => data.ga_paid_revenue_aggregate.aggregate.count)

    const options = reactive({
      chart: {
        id: "paid",

        type: "area",

        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: true,
        },
      },

      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      stroke: {
        curve: "smooth",
      },

      grid: {
        borderColor: "#e7e7e7",
     
      },
      markers: {
        size: 5,
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      xaxis: {
        categories: useResult(result, null, (data) =>
          data.ga_paid_revenue.map((a) =>
            moment(a.ga_yearMonth).format("MMM-YY")
          )
        ),
        title: {
          text: "Month",
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
        },
      },
      yaxis: { forceNiceScale: true, decimalsInFloat: false },
dataLabels: {
  enabled: true,
  enabledOnSeries: [0,1],
  formatter: function (val) {
      return '£'+val.toLocaleString()
  },
  textAnchor: 'middle',
  distributed: false,
  offsetX: 0,
  offsetY: 10,
  style: {
      fontSize: '12px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      colors: undefined
  },
  dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45
  }
} ,fill: {
              type: 'gradient',
              gradient: {
                opacityFrom: 0.8,
                opacityTo: 0.1,
              }
            },
    });
    const series = reactive([
   
      {
        name: "revenue",
        type: "area",
        data: useResult(result, null, (data) =>
          data.ga_paid_revenue.map((a) => a.revenue?a.revenue.toFixed(0):0)
        ),
      },   {
        name: "cost",

        type: "area",
        data: useResult(result, null, (data) =>
          data.ga_paid_revenue.map((a) => a.ga_adCost.toFixed(0))
        ),
      },
      /* {
        name: "Ad Clicks",
        type: "bar",
   
        data: useResult(result, null, (data) =>
          data.ga_paid_revenue.map((a) => a.ga_adClicks.toFixed(0))
        ),
      },
        {
        name: "Impressions",
        type: "bar",
   
        data: useResult(result, null, (data) =>
          data.ga_paid_revenue.map((a) => a.ga_impressions.toFixed(0))
        ),
      },*/
    ]);
  const columns=[
     
        {
          prop: "ga_yearMonth",
          label: "Date",
          minWidth: "90",
          sortable: true,
          type:"date",
          formatter: (row) => moment(row.ga_yearMonth).format('MMM YY')
        },
          {
          prop: "ga_adClicks",
          label: "Clicks",
          minWidth: "90",
          sortable: false,
          type: "number",
        formatter: (row) => row.ga_adClicks.toLocaleString()
        },
        {
          prop: "ga_impressions",
          label: "Impressions",
          minWidth: "120",
          sortable: false,
          type:"string",
        formatter: (row) => row.ga_impressions.toLocaleString()
        },
        
        {
          prop: "ga_CTR",
          label: "CTR",
          minWidth: "100",
          sortable: false,
          type: "number",
        formatter: (row) => row.ga_CTR.toFixed(2)+'%'
        },
        
        {
          prop: "ga_CPC",
          label: "CPC",
          minWidth: "100",
          sortable: false,
          type: "number",
          formatter: (row) => row.ga_CPC.toFixed(2)
        },
        {
          prop: "ga_adCost",
          label: "Cost",
          minWidth: "140",
          sortable: false,
          type:"string",
          formatter: (row) => '£'+Number(row.ga_adCost.toFixed(2)).toLocaleString()
        },
        {
          prop: "revenue",
          label: "Revenue",
          minWidth: "140",
          sortable: false,
          type:"string",
          formatter: (row) => row.revenue?'£'+Number(row.revenue.toFixed(2)).toLocaleString():0
        }
      
        
      ]
      const paid= useResult(result,null,data => data.ga_paid_revenue)
    const total = useResult(result,null,data => data.ga_paid_revenue_aggregate.aggregate.count)
    return {
      paid,
      total,
      columns,
      result,
      series,
      options,
      loading,
      showGraph,
      toggleDisplay
    };
  },
};
</script>
<style scoped>
.sm {
  transform: scale(0.8);
  transform-origin: 0 0;
}
</style>