<template>
  <el-card v-if="!loading " class="box-card">
    <template #header>
      <div class="card-header">
        <h3>Sales by Country</h3>
        <span class="text-muted">By Month Source:Stripe & paypal card</span>
      </div>
    </template>
    <el-form ref="form"  label-width="120px">
      <el-form-item label="Date Range">
    <el-date-picker
      v-model="test"
      type="monthrange"
 format="MMM YY"
 
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
    @change="dateChanged"
     :default-value="[from, to]"
    >
    </el-date-picker>
      </el-form-item>
     <el-form-item label="Top X countrys">
    <el-select v-model="top" @change="topChanged" placeholder="Top X countrys">
    <el-option
      v-for="item in topRange"
      :key="item"
      :label="item"
      :value="item">
    </el-option>

  </el-select>
     </el-form-item>
    </el-form>

    <apexchart
    id="countries"
      v-if="!loading && series"
      width="95%"
      height="685px"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
      
  </el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
//import Sales from "../graphql/sales_country_month.gql";
import { ref, reactive } from "vue";
import moment from "moment";
//import codes from "../helpers/country_codes"
import filters from "../filters/filters";
import { GetSalesByCountry } from "../graphql/dynamic/sales_by_country_month.query";
export default {
  setup() {
    let topRange = [10, 20, 30];
    let from = ref(moment().startOf("month").format("YYYY-MM-DD"));
    let to = ref(moment().startOf("month").format("YYYY-MM-DD"));
    let test = ref([from.value, to.value]);
    ////const variables = reactive({
    //from:from,
    //to:to
    //})
    const query = ref();
    const top = ref();
    top.value = 10;
    query.value = GetSalesByCountry(from.value, to.value, top.value);

    function dateChanged(val) {
      from.value = moment(val[0]).startOf("month").format("YYYY-MM-DD");
      to.value = moment(val[1]).add(1,'month').startOf("month").format("YYYY-MM-DD");
      query.value = GetSalesByCountry(from.value, to.value, top.value);
    }
    function topChanged(){
      query.value = GetSalesByCountry(from.value, to.value, top.value);}
    const { result, loading } = useQuery(query,{ fetchPolicy: "no-cache"});

    const options = reactive({
      chart: {
        id: "countries",
        stacked: false, 
        type:"bar",
         toolbar: {
          show: true
        },
      },
    
      plotOptions: {
        bar: {
         
          distributed: false,
          horizontal: false,
         
          dataLabels: {
            position: "top",
          },
        },
      },
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],

      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: ["#000"],
        },
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
            .x; //+ ":  £" + val.toLocaleString()
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      yaxis: {  decimalsInFloat: false,tickAmount: 6,  },
      xaxis: {
  
        categories: useResult(result, null, (data) => {
          let val=0
          let k = Object.keys(data)[0];
         val = data[k].length          
          console.log(val)
          return Array.from({ length: val }, (_, i) => i + 1);
        }),
      },
      grid: {
        padding: {
          top: 20,
          bottom: 10,
          left: 10,
        },
      },
    });

    const series = useResult(result, null, (data) => {
      let g = [];
      Object.entries(data).map(([key, value]) => {
        g.push({
          name: key,
          data: value.map((a) => {
            return { x: a.country, y: a.total };
          }),
        });
      });

      console.log(g);

      return g;
    });



    return {

      top,
      topRange,
      loading,
      result,
      options,
      series,
      filters,
      to,
      from,
      test,
      topChanged,
      dateChanged,
    };
  },
};
</script>