  <template>
  <div class="sidebar-menu" data-background-color="brown">
  <Logo class="logo" />
   <UserMenu ></UserMenu>
  <el-menu
    class="el-menu-vertical-demo"
    background-color="#545c64"
    text-color="#fff"
    width="260px"
    active-text-color="#ffd04b"
    style="zIndex:100;width:260px"
    :router="true"
    
  >
    <div v-for="(item, indexe) in menu" :key="indexe">
      <template v-if="itemHasChild(item)">
        <el-submenu :index="indexe.toString()">
          <template #title>
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>

          <el-menu-item
          :route="sub.link"
            v-for="(sub, index) in item.child"
            :index="indexe + '-' + index"
            :key="index"
            ><i :class="sub.icon"></i>{{ sub.name }}</el-menu-item
          >
        </el-submenu>
      </template>
      <template v-if="!itemHasChild(item)">
        <el-menu-item :index="indexe.toString()" :route="item.link">
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </el-menu-item>
      </template>
    </div>
  </el-menu>
  </div>
</template>


    <script>
    import Logo from "./Logo";
    import UserMenu from "./UserMenu"
export default {
  name: "Menu",
    components: {
    Logo,
    UserMenu
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  setup(){
 const itemHasChild = (item) => {
      return !!(item.child && item.child.length > 0);
    }
    return {itemHasChild }
  },
 
};
</script>
<style scoped>
i {
  margin-right: 10px;
}
</style>