<template>
<div>
  <el-row :gutter="2">
    <el-col :span="12">
      <!--<Target />-->
      <day /><customers /><web />
    </el-col>
    <el-col :span="12">
      <sales />
      <paid />
    </el-col>
  </el-row>
 
</div>
</template>
<script>
import Web from "../components/web_users";
//import Target from "../components/DB-Daily-Sales-Figures/Targets";
import Paid from "../components/paid_month_graph";
import Day from "../components/DB-Daily-Sales-Figures/Day";
import Sales from "../components/DB-Daily-Sales-Figures/Sales";
import Customers from "@/components/customers"

export default {
  name: "Dashboard",
  components: {
    Web,
    Paid,
    Day,
    Sales,
    Customers
   // Target
  }
};
</script>
<style scoped>
html,body{
zoom: 80%
}
</style>