<template>
<el-card v-if="!loading " class="box-card">
    <template #header>
      <div class="card-header">
        <h3>Sales by Value(Average)</h3>
        <span class="text-muted">Source:Stripe & Paypal transactions</span>
      </div>
    </template>
    
<el-form ref="form"  label-width="120px"><el-row :gutter="20">
    <el-col :span="4">
    <el-form-item label="Date Range">
    <el-date-picker
      v-model="test"
      type="monthrange"
 format="MMM YY"
 
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
    @change="dateChanged"
     :default-value="[from, to]"
    >
    </el-date-picker>
      </el-form-item>
  </el-col>
 </el-row>
 </el-form> 

    <div id="chart" style="width:100%">
   <apexchart
   id="products"
      v-if="!loading && series"
 width="100%"
      height="500"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
</div>
</el-card>
</template>
<script>
import { ref, reactive } from "vue";
import moment from "moment";
import { useQuery, useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";

export default {
  setup() {
    let from = ref(moment().subtract(2,'years').format("YYYY-MM-DD"));
    let to = ref(moment().startOf("month").format("YYYY-MM-DD"));
  let test = ref([from.value, to.value]);

 function dateChanged(val) {
      from.value = moment(val[0]).startOf("month").format("YYYY-MM-DD");
      to.value = moment(val[1]).add(1,'month').startOf("month").format("YYYY-MM-DD");
  
    }

    const query = reactive(gql`
      query MyQuery($startdate: date, $enddate: date) {
        sales: sales_by_average_value(
          where: { year_month: { _gte: $startdate, _lte: $enddate } }
          order_by: { year_month: desc }
        ) {
          year_month
          orders
          average
        }
      }
    `);
    const variables = reactive({
      startdate: from,
      enddate: to,
    });
    const { result, loading } = useQuery(query, variables);

    const options = reactive({
      chart: {
        id: "average",
        type: "line",
        toolbar: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },

      colors: ["#33b2df", "#546E7A"],
      stroke: {
        curve: "smooth",
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      noData: {
        text: undefined,
      },
      xaxis: {
        type: "datetime",
        categories: useResult(result, null, (data) =>
          data.sales.map((a) => a.year_month)
        ),
      },
      yaxis: [
        {
          axisTicks: {
            show: false,
          },
          decimalsInFloat: false,
          axisBorder: {
            show: true,
            color: "#33b2df",
          },
          labels: {
            style: {
              colors: "#33b2df",
            },
          },
          title: {
            text: "Orders",
            style: {
              color: "#008FFB",
               fontSize: '15px',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: "Average",
           min: 120,
      max: 180,
           decimalsInFloat: false,
          opposite: true,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: true,
            color: '#546E7A"',
          },
          labels: {
            style: {
              colors: '#546E7A"',
              
            },
          },
          title: {
            text: "Average",
            style: {
              color: '#546E7A"',
               fontSize: '15px',
            },
          },
        },
      ],
      tooltip: {
        items: {
          display: "flex",
        },
        marker: {
          show: true,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",

        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - " +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ""
          );
        },
      },
      markers: {
        size: 1,
        hover: {
          sizeOffset: 6,
        },
      },
    });
    const series = reactive([
      {
        name: "Orders",
        type: "column",
        data: useResult(result, null, (data) =>
          data.sales.map((a) => a.orders)
        ),
      },
      {
        name: "Average",
        type: "line",
        data: useResult(result, null, (data) =>
          data.sales.map((a) => a.average.toFixed(2))
        ),
      },
    ]);

    return {
        test,
        to,
        from,
      dateChanged,
      loading,
      series,
      options,
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>
<style scoped>
#chart {
  zoom: 120%;
}
</style>