<template>
    <el-container>
      <el-aside width="260px">
      <Menu :menu="menu"  />
      </el-aside>
      <el-container>
        <Header />
        <el-main>   
          <router-view /></el-main>
        <el-footer>Footer</el-footer>
      </el-container>
    </el-container>
</template>

<script>
import Menu from "../components/UI/Menu"
import Header from "../components/UI/Header"
import {ref} from 'vue'
import Links from '../sidebarLinks'
export default {
  name: "App",
  components: {
    Menu,
    Header
  },
  setup(){
    const menu= ref(Links)
     
      return {menu} 
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      this.width = "20px";
      console.log(key, keyPath);
    },
      handleClick() {
        console.log('click');
      }
  },
};
</script>

<style lang="scss">
html,body{
  height:100%;
  padding:0%;
  margin:0;
}
.el-main{display:block !important;position:fixed;height: calc(100% - 120px);overflow:auto;width:88%;bottom:0;top:60px;left:260px;background-color:#f5f5f5}
.el-footer{display:block !important;position:fixed;bottom:0;}
.el-container{display:block;}
</style>
