<template>
<el-card class="box-card">
   <template #header>
    <div class="clearfix">
      <span>Stripe Transactions</span>
     <!-- <el-button style="float: right; padding: 3px 0" type="text">Operation button</el-button>-->
    </div>
  </template>
  <el-date-picker
      v-model="from"
      type="date"
      placeholder="From"
   
    >  </el-date-picker>
    <el-date-picker
      v-model="to"
      type="date"
      placeholder="From"
   
    ></el-date-picker>
    <data-table v-if="paid" :data="paid"
                :total="total"
                :loading="loading"
                ref="table"
                :columns="columns"
                :showEdit="false"
                 :showSummary="true"
                 :summary="getSummaries"
            :rowClassName="formatRows"
                v-on:pageSizeChanged="pageSizeChanged"
                v-on:pageCurrentChanged="pageCurrentChanged"
                :pageSize="variables.limit"
              >
          
    </data-table>


</el-card>
</template>
<script>
import  { useQuery, useResult } from '@vue/apollo-composable'
import DataTable from "../components/UI/DataTable";
import Stripe from "../graphql/stripe_balance_transactions.gql"
import { reactive,ref } from 'vue'
import filters from "../filters/filters"
import moment from "moment";
export default {
      name: "Stripe US",
  components: {
      DataTable
  },
   setup() {  
     let from =ref(moment().subtract(1,'days').format('YYYY-MM-DD'));
 let to=ref(moment().format('YYYY-MM-DD'))
 const variables = reactive({
from:from,
to:to,
  limit: 10,
  offset:0
})
const {result,loading} =  useQuery(Stripe,variables);

const pageSizeChanged=function (ev){
  variables.limit=ev
}
const pageCurrentChanged=function (page){
  variables.offset=(page-1)*variables.limit
}
const columns=[
     
        {
          prop: "created",
          label: "Date Time",
          minWidth: "50",
          sortable: true,
          type:"date",
          formatter: (row) => moment(row.created).format('DD/MM/YY hh:mm:ss')
        },
        {
          prop: "description",
          label: "Desc",
          minWidth: "80",
          sortable: false,
          type:"string",
        //  formatter: (row) => moment(row.created).format('DD/MM/YY HH mm')
        },
        {
          prop: "status",
          label: "Status",
          minWidth: "20",
          sortable: false,
          type:"string",
        //  formatter: (row) => moment(row.created).format('DD/MM/YY HH mm')
        },
        {
          prop: "original_currency",
          label: "Currency",
          minWidth: "20",
          sortable: true,
          type:"string",
       //  formatter: (row) => moment(row.created).format('DD/MM/YY HH mm')
        },
        
        
        {
          prop: "type",
          label: "Type",
          minWidth: "50",
          sortable: false,
          type:"string",
          //formatter: (row) => Number((row.amount/100).toFixed(2)).toLocaleString()
        },
        
        {
          prop: "amount",
          label: "Amount",
          minWidth: "50",
          sortable: false,
          type:"number",
          formatter: (row) => filters.formatCurrencyCodeToSymbol(row.currency) + Number((row.amount/100).toFixed(2)).toLocaleString()
        },
        
        {
          prop: "card_country",
          label: "Country",
          minWidth: "20",
          sortable: false,
         
          type:"string",
         // formatter: (row) => "<img src='/flags/"+row.card_country+".png' />"
        },
         {
          prop: "exrate",
          label: "Ex rate",
          minWidth: "20",
          sortable: false,
         
          type:"string",
         // formatter: (row) => "<img src='/flags/"+row.card_country+".png' />"
        },
         {
          prop: "examount",
          label: "Converted",
          minWidth: "20",
          sortable: false,
         
          type:"string",
         // formatter: (row) => "<img src='/flags/"+row.card_country+".png' />"
        }
       
      
        
      ]
    function getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index,arr) => {
          if (index === 0) {
            sums[index] = 'Total ';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value)) && index===arr.length - 1) {
            sums[index] = '£'+ values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return (prev + curr)
              } else {
                return ( prev)
              }
            }, 0).toFixed(2);
          } else {
            sums[index] = '';
          }
        })

        return sums;
      }
    
 
     function formatRows({row}) {
   
        if (row.type === 'payout') {
          return 'payout-row text-muted';
        }
        
        if (row.type === 'refund') {
          return 'refund-row text-muted';
        }
        return '';
      }
 const paid= useResult(result,null,data => data.stripe_balance_transactions)
    const total = useResult(result,null,data => data.stripe_balance_transactions_aggregate.aggregate.count)
    return{
     loading,result,paid,total,columns,pageSizeChanged,pageCurrentChanged,from,to,formatRows,getSummaries,variables
     }
   }
}
</script>
<style>
  .el-table .payout-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
    .el-table .refund-row {
    background: #f58181;
  }
</style>