import gql from 'graphql-tag'
import moment from 'moment';

function getRange(startDate, endDate, type) {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, type)
    let range = []
    for (let i = 0; i < diff; i++) {
        range.push(moment(startDate).add(i, type))
    }
    return range
}
export function GetSalesByCountry(startDate, endDate,limit) {

    const range = getRange(startDate, endDate, 'month')
   
    if (range.length == 0) {
        return gql `
        query MyQuery {
            ${moment(startDate).format("MMM")}:sales_by_country(where: {month: {_eq: "${startDate}"}, total: {_is_null: false}}, limit: ${limit}) {
                month
                country
                total
            }
        }
        `
    } else {
        let query = String.raw ` query MyQuery {`;
        let x = 0;
        while (x < range.length) {
            query += `
            ${moment(range[x]).format("MMM")}:sales_by_country(where: {month: {_eq: "${moment(range[x]).format('YYYY-MM-DD')}"}, total: {_is_null: false}}, limit: ${limit}) {
                        month
                        country
                        total
                    }
                `;
            x++;
        }
        query += `}`

        return gql(query)
    }
    //return gql``

}