<template>
<el-card v-if="!loading " class="box-card">
    <template #header>
      <div class="card-header">
        <h3>Sales by Product</h3>
        <span class="text-muted">By Product Source:Admin orders</span>
      </div>
    </template>
    
<el-form ref="form"  label-width="190px"><el-row :gutter="20">
    <el-col :span="4">
  <el-form-item label="Products">
    <el-select v-model="selectedProduct" multiple placeholder="please select your zone">
        <el-option-group
      v-for="group in productTypes"
      :key="group"
      :label="group">
      <el-option v-for="product in products.filter((a)=>a.type==group)" :key="product.id" :label="product.name" :value="product.id"></el-option>
     </el-option-group>
    </el-select>
    <el-button type="danger" @click="selectedProduct=[]" round size="small">Clear</el-button>
  </el-form-item>
    </el-col>  <el-col :span="14">
       <el-form-item label="Quick select">
  <el-button type="primary" @click="selectedProduct=virtuals">Virtuals</el-button>
  <el-button type="primary" @click="selectedProduct=downloads">PDF</el-button>
  <el-button type="primary" @click="selectedProduct=level5">Level 5</el-button>
   <el-button type="primary" @click="selectedProduct=extensions">Extensions</el-button>
   <el-button type="primary" @click="selectedProduct=advanced">Advanced</el-button>
    <el-button type="primary" @click="selectedProduct=premier">120hrs</el-button>
       </el-form-item>
  </el-col>
 </el-row>
 </el-form> 

    <div id="chart" >
   <apexchart
   id="products"
      v-if="!loading && series"
 width="100%"
      height="500"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
</div>
</el-card>
</template>
<script>
import { ref, reactive } from "vue";
import moment from "moment";
import { useQuery, useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import productData from "@/data/products.json";

export default {
  setup() {
    let selectedProduct = ref([25, 26, 10186, 10184]);
    let productTypes = [
      "classroom course",
      "online course",
      "combined course",
      "course extra",
      "download",
      "extras",
      "online extension",
    ];
    let products = productData.data.Product;
    let virtuals = [10166, 10171, 10174, 10167, 10169, 10168, 10172,10186];
    let downloads = [28, 10165, 10164, 10162, 10163, 119, 29, 118, 117, 116, 131,10188];
    let level5=[ 10183,10184,10185,10186,10187 ];
    let extensions = [ 153, 155, 157, 159, 154, 156, 158, 160 ];
    let advanced = [ 144, 145, 105, 106, 10161, 107, 10179,10189,10191 ]
    let premier = [ 25,10167,18 ]

    
    const query = reactive({
      q: gql`
        query MyQuery($ids: [Int!]) {
                      monthly_total_product_sales {
    date_trunc
    total
  }
            monthly_total_product_sales {
    date_trunc
    total
  }
          sales_by_product(where: { pid: { _in: $ids } },limit:10) {
            pid
            total
          }
        }
      `,
    });
 
    const variables = reactive({
      ids: selectedProduct,
    });
    const { result, loading } = useQuery(query.q, variables);

    const options = reactive({
      chart: {
 id: 'products',
        type: "line",
         toolbar: {
          show: true,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        
    
      },
    
      colors: [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ],
      stroke: {
        curve: "smooth",
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      noData: {
  text: undefined,
      },
      xaxis: {
        type: "datetime",
        categories: useResult(result, null, (data) =>
          data.sales_by_product[0].total.map((a) =>
           moment(a.month).format('YYYY-MM-DD')
          )
        ),
      },
      
            tooltip: {
               items: {
        
      },   marker: {
          show: true,
      },
              
            },
        legend: {
          position: 'top',
              horizontalAlign: 'left',
           
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 1,
              hover: {
                sizeOffset: 6
              }
            },
    });


 //prod=prod[0]
    const series = reactive(
    useResult(result, null, (data) =>{
       let g= data.sales_by_product.map((a) => {
          return {
            type:"line",
            name: a.total[0].product,
            data: a.total.map((b) => {return{x:b.month,y:b.total}}),
          }
        })
      
      let c= {type:"column",name:"Total",data:data.monthly_total_product_sales.map((a) => {return{x:a.date_trunc,y:a.total}})};
      return g.concat(c)
    }
  )
    )
    return {
      query,
      loading,
      series,
      options,
      selectedProduct,
      productTypes,
      products,
      virtuals,
      downloads,
      level5,
      extensions,
      advanced,
      premier
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>
<style scoped>
#chart{
    zoom: 120%
    }
</style>