import { createWebHistory, createRouter } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import About from "@/views/About.vue";
import Stripe from "@/views/Stripe.vue";
import Paypal from "@/views/Paypal.vue";
import Login from "@/views/Login.vue";
import Layout from "@/views/Layout";
import SalesCountry from "@/views/SalesCountry";
import SalesMonth from "@/views/SalesMonth";
import SalesProduct from "@/views/SalesProduct"
import Search from "@/views/analytics/Search";
import Average from "@/views/SalesValue";
const ifAuthenticated = (to, from, next) => {
  if (window.localStorage.getItem('jwt_token')) {
    next()
    return
  }
  next('/login')
}
const routes = [
  {
    path: "/",
    name: "Layout ",
    component: Layout,
    beforeEnter: ifAuthenticated ,
    children:[
  {  beforeEnter: ifAuthenticated,
    path: "/",
    name: "Home",
    component: Dashboard,
  },
  {  beforeEnter: ifAuthenticated,
    path: "/sales",
    name: "About",
    component: About,
  },
  {  beforeEnter: ifAuthenticated ,
    path: "/sales/stripe",
    name: "Stripe",
    component: Stripe,
  },
  {  beforeEnter: ifAuthenticated ,
    path: "/sales/paypal",
    name: "Paypal",
    component: Paypal,
  },
  {  beforeEnter: ifAuthenticated ,
    path: "/sales/country",
    name: "Sales Country",
    component: SalesCountry,
  },
  {  beforeEnter: ifAuthenticated ,
    path: "/sales/month",
    name: "Sales Months",
    component: SalesMonth,
  },
  {  beforeEnter: ifAuthenticated ,
    path: "/sales/product",
    name: "Sales Product",
    component: SalesProduct,
  }
  ,
  {  beforeEnter: ifAuthenticated ,
    path: "/sales/value",
    name: "Sales Value",
    component: Average,
  }
  ,
  {  beforeEnter: ifAuthenticated ,
    path: "/analytics/search",
    name: "Sales Months",
    component: Search,
  }
  
]
},
  {
    path: "/login",
    name: "Login",
    component: Login,
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;