 import moment from 'moment';
 export default {

     formatDateToMonth(value) {
         if (value) {
             return moment(String(value)).format('MMM YY')
         }
     },
     formatCurrencyCodeToSymbol(value) {
        if (value.toUpperCase()=='GBP') {
            return '£'
        }
        if (value.toUpperCase()=='EUR') {
            return '€'
        }
    }

 }