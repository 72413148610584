<template>
  <el-card v-if="!loading" class="box-card">
    <template #header>
      <div class="card-header">
        <h3>Search Positions by Country</h3>
        <span class="text-muted">Source:Google Search Console</span>
      </div>
    </template>
     <el-form label-position="top">
       <el-form-item label="Date Range">
    <el-date-picker
      v-model="newDates"
      type="monthrange"
 format="MMM YY"
 
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
    @change="dateChanged"
     :default-value="[from, to]"
    >
    </el-date-picker>
       </el-form-item>
       <el-form-item label="Countries">
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox>
  <div style="margin: 15px 0;"></div>
  <el-checkbox-group v-model="checkedCountries" @change="handleCheckedCitiesChange">
    <el-checkbox-button v-for="country in countries" :label="country" :key="country">{{codes[country.substring(0,2).toUpperCase()]}}</el-checkbox-button>
  </el-checkbox-group>
       </el-form-item>
     </el-form>
    <apexchart
      v-if="!loading"
      width="100%"
      height="400"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
    
  </el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import Sales from "./search_by_country.gql";
import {ref, reactive} from "vue";
import moment from "moment";
import codes from "../../helpers/country_codes"
import filters from "../../filters/filters"
export default {
  setup() { 
    const countryOptions = ref(['gbr', 'usa','zaf','esp','irl','can']);
  let checkAll= false
  const checkedCountries= ref(['gbr', 'usa'])
        const countries=ref(countryOptions)
       const isIndeterminate = ref(true)
      
     let from =ref(moment().subtract(1,'years').startOf('month').format('YYYY-MM-DD'));
 let to=ref(moment().add(1,'month').endOf('month').format('YYYY-MM-DD'))
 let newDates=ref([from.value,to.value])
 const variables = reactive({
from:from,
to:to,
countries:checkedCountries
})

    function dateChanged(val){

from.value=moment(val[0]).startOf('month').format('YYYY-MM-DD');
to.value=moment(val[1]).add(1,'month').endOf('month').format('YYYY-MM-DD')
    }
    const { result, loading } = useQuery(Sales, variables);

    const options = reactive({
       chart: {
              height: 400,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
          dataLabels: {
              enabled: true,
            },
            colors:['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
              '#f48024', '#69d2e7'
            ],
            stroke: {
              curve: 'smooth'
            },
         
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories:  useResult(result, null, (data) =>
          data.search_by_country.filter(a => a.gsc_country=='gbr').map(a => a.date)),
            }
    });
    const series = reactive(useResult(result, null, (data) =>
checkedCountries.value.map((country) => {

return {
  name:country,
  data: data.search_by_country.filter(a => a.gsc_country==country).map(a => a.position.toFixed(2))
}
})))

   function handleCheckAllChange(val) {
     console.log(val)
         val ? checkedCountries.value=countryOptions.value : checkedCountries.value=['gbr'];
        isIndeterminate.value = false;
      }
    function  handleCheckedCitiesChange(value) {
      console.log(value)
        let checkedCount = value.length;
       checkAll = checkedCount === countries.value.length;
        isIndeterminate.value = checkedCount > 0 && checkedCount < countries.value.length;
      }


    return { loading, result,codes, options, series, filters,to,from,newDates,dateChanged,countryOptions,checkAll,countries,checkedCountries,isIndeterminate,handleCheckAllChange,handleCheckedCitiesChange  };
  },
};
</script>