<template>
<div class="logo">
      <router-link  to= "/">
      <a class="simple-text logo-mini"
         href="javascript:void(0)">
          <div class="logo-img">
            <img :src="logo" alt="TEFL Org Logo">
           
          </div>
      </a>
      </router-link>
</div>
</template>
<script>
  export default {
    props: {
        logo: {
        type: String,
        default: '/TEFL-Logo-White.svg'
      }
    }
  }
  </script>