<template>
<el-card class="box-card">
   <template #header>
    <div class="clearfix">
      <span>Paypal Transactions</span>
     <!-- <el-button style="float: right; padding: 3px 0" type="text">Operation button</el-button>-->
    </div>
  </template>
  <el-date-picker
      v-model="from"
      type="date"
      placeholder="From"
   
    >  </el-date-picker>
    <el-date-picker
      v-model="to"
      type="date"
      placeholder="From"
   
    ></el-date-picker>
    <data-table v-if="paid" :data="paid"
                :total="total"
                ref="table"
                :columns="columns"
                :showEdit="false"
                :showSummary="true"
                :summary="getSummaries"
               :rowClassName="formatRows"
                v-on:pageSizeChanged="pageSizeChanged"
                v-on:pageCurrentChanged="pageCurrentChanged"
                :pageSize="variables.limit"
              >
          
    </data-table>


</el-card>
</template>
<script>
import  { useQuery, useResult } from '@vue/apollo-composable'
import DataTable from "../components/UI/DataTable";
import Stripe from "../graphql/paypal_transactions.gql"
import { reactive,ref } from 'vue'
import moment from "moment";
import eventcodes from "../helpers/paypal_event_codes"
export default {
      name: "Stripe US",
  components: {
      DataTable
  },
   setup() {  let from =ref(moment().subtract(1,'days').format('YYYY-MM-DD'));
 let to=ref(moment().format('YYYY-MM-DD'))
 const variables = reactive({
from:from,
to:to,
  limit: 10,
  offset:0
})
const {result} =  useQuery(Stripe,variables);

const pageSizeChanged=function (ev){
  variables.limit=ev
}
const pageCurrentChanged=function (page){
  variables.offset=(page-1)*variables.limit
}
const columns=[
     
        {
          prop: "transaction_initiation_date",
          label: "Date Time",
          minWidth: "50",
          sortable: true,
          type:"date",
          formatter: (row) => moment(row.transaction_initiation_date).format('DD/MM/YY hh:mm:ss')
        },
        //transaction_id
           {
          prop: "transaction_id",
          label: "ID",
          minWidth: "50",
          sortable: true,
          type:"string",
         // formatter: (row) => codeToText(row.transaction_event_code)
        },
         {
          prop: "transaction_event_code",
          label: "Event",
          minWidth: "50",
          sortable: true,
          type:"string",
          formatter: (row) => codeToText(row.transaction_event_code)
        },
         {
          prop: "transaction_amount_currency_code",
          label: "Currency",
          minWidth: "50",
          sortable: true,
          type:"string",
          //formatter: (row) => row.transaction_amount_value.toLocaleString()
        },
     {
          prop: "transaction_amount_value",
          label: "Amount",
          minWidth: "50",
          sortable: true,
          type:"number",
          formatter: (row) => row.transaction_amount_value.toLocaleString()
        },
          {
          prop: "payer_country",
          label: "Card Country",
          minWidth: "50",
          sortable: true,
          type:"string",
         // formatter: (row) => row.transaction_amount_value.toLocaleString()
        },
         {
          prop: "exrate",
          label: "Exchange rate",
          minWidth: "20",
          sortable: false,
         
          type:"string",
         // formatter: (row) => "<img src='/flags/"+row.card_country+".png' />"
        },
         {
          prop: "examount",
          label: "Converted",
          minWidth: "20",
          sortable: false,
         
          type:"string",
         // formatter: (row) => "<img src='/flags/"+row.card_country+".png' />"
        }
       
      
        
      ]
           function formatRows({row}) {
   
        if (row.transaction_event_code !== 'T0006') {
          return 'payout-row text-muted';
        }
        
      
        return '';
      }
     function codeToText(code){
return eventcodes[code];
     }
         function getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index,arr) => {
          if (index === 0) {
            sums[index] = 'Total ';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value)) && index===arr.length - 1) {
            sums[index] = '£'+ values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return (prev + curr)
              } else {
                return ( prev)
              }
            }, 0).toFixed(2);
          } else {
            sums[index] = '';
          }
        })

        return sums;
      }
 const paid= useResult(result,null,data => data.paypal_transactions)
    const total = useResult(result,null,data => data.paypal_transactions_aggregate.aggregate.count)
    return{
     result,paid,total,columns,pageSizeChanged,pageCurrentChanged,from,to,formatRows,getSummaries,variables
     }
   }
}
</script>
<style>
  .el-table .payout-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
    .el-table .refund-row {
    background: #f58181;
  }
</style>