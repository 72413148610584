<template>
<div>
About
<tsales />
</div>
</template>
<script>
import tsales from "../components/Sales_Totals"
export default {
      name: "Dashboard",
  components: {
      tsales 
  }
}
</script>