<template>
<el-card v-if="!loading" class="box-card">
    <template #header>
    <div class="card-header">
      <h3>Customers</h3>
      <span class="text-muted">Source:Admin (last 12 months)</span>
    </div>
    <el-row>
        <el-col :span="6">
    <h5>Total Customers</h5><el-tag :hit="true" type="info">{{totalCustomers.toLocaleString()}}</el-tag><br/>
  
        </el-col>
         <el-col :span="6">
   <h5>Total Orders</h5><el-tag type="info">{{totalOrders.toLocaleString()}}</el-tag>
         </el-col>
    </el-row>
  </template>


</el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { reactive } from "vue";
import moment from "moment";
import gql from "graphql-tag";
export default {
  setup() {
    let query = gql`
      query MyQuery($start: date, $end: date) {
        customers: orders_aggregate(
          where: { dateCreated: { _gte: $start, _lte: $end } }
        ) {
          aggregate {
            count(columns: customerId, distinct: true)
          }
        }
        orders: orders_aggregate(
          where: { dateCreated: { _gte: "2020-06-01", _lte: "2021-06-01" } }
        ) {
          aggregate {
            count(columns: orderId, distinct: true)
          }
        }
      }
    `;
    const variables = reactive({
      start: moment().subtract(1, "years").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    });
    const { result, loading } = useQuery(query, variables);
    const totalCustomers = useResult(
      result,
      null,
      (data) => data.customers.aggregate.count
    );
    const totalOrders = useResult(
      result,
      null,
      (data) => data.orders.aggregate.count
    );

   
    return { loading, totalCustomers, totalOrders };
  },
};
</script>