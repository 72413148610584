<template>
  <el-card v-if="!loading" class="box-card" shadow="hover">
    <template #header>
      <div class="card-header">
        <h3>Sales by Days</h3>
        <span class="text-muted">By Month Source:GA</span>
      </div>
    </template>
    <apexchart
      v-if="!loading"
      width="100%"
      height="285px"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
    
  </el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { reactive } from "vue";
export default {
  setup() {
    const variables = {
      from: "2021-01-01",
      to: "2021-03-01",
    };
    const query = gql`
      query getSales($from: date, $to: date) {
        eu_sales(where: { date: { _gte: $from, _lte: $to } }) {
          total
          date
        }
        uk_sales(where: { date: { _gte: $from, _lte: $to } }) {
          total
          date
        }
        us_sales(where: { date: { _gte: $from, _lte: $to } }) {
          total
          date
        }
      }
    `;
    const { result, loading } = useQuery(query, variables);

    const t =  useResult(result, null, (data) => {
      //merge the 3 arays into one, then reduce by dat ket
      let merged = data.eu_sales.concat(data.uk_sales, data.us_sales);
      const res = Array.from(
        merged.reduce(
          (m, { date, total }) => m.set(date, (m.get(date) || 0) + total),
          new Map()
        ),
        ([date, total]) => ({ date, total })
      );
      return res;
    });

    const options = reactive({
      chart: {
        id: "users",
        stacked: true,
      },
      yaxis: { forceNiceScale: true, decimalsInFloat: false },
      xaxis: {
        categories: t.date,
      },
      grid: {
        padding: {
          top: 20,
          bottom: 10,
          left: 10,
        },
      },
    });
    const series = reactive([
      {
        name: "users",
        data: t.total,
      },
    ]);
    return { loading, result, options, series,t };
  },
};
</script>