import { createApp, provide,h } from 'vue'
import App from './App.vue'
import ElementPlus from './plugins/element'
import './sass/element_variables.scss'
import router from './router'
import { ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {DefaultApolloClient} from '@vue/apollo-composable';
import VueApexCharts from "vue3-apexcharts";
import store from './store';
const getHeaders = () => {
    const headers = {}
    headers["x-hasura-admin-secret"]= "98yz@Fs%"
    const token = window.localStorage.getItem('jwt_token')
    if (token) {
      
      headers.authorization = `Bearer ${token}`
      headers['access-control-allow-credentials'] = true
    }
    return headers
  }
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_HASURA_SERVER,//'https://graphql-stats.tefl.org/v1/graphql',//,'http://localhost:8080/v1/graphql'
    headers: getHeaders()
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
const defaultClient = new ApolloClient({

    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    }),

})

createApp({
    setup () {
        provide(DefaultApolloClient, defaultClient,router)
      },
      render(){
          return h(App)
      }
})
.use(ElementPlus, { size: 'large', zIndex: 3000 })
.use(router).use(store).use(VueApexCharts).mount('#app')
