<template>
  <el-card v-if="!loading" class="box-card" shadow="hover">
    <template #header>
      <div class="card-header">
        <h3>Total Sales</h3>
        <span class="text-muted">Stripe & Paypal Aggregated</span>
      </div>
    </template>
    <el-row>
      <el-col :span="24">
        <div class="table-responsive">
          <table class="table table-striped" width="100%">
            <thead>
              <tr>
                <th></th>

                <th class="text-right">Yesterday</th>
                <th class="text-right">MTD</th>
                <th class="text-right">last year</th>
                <th class="text-right">+/- %</th>
              </tr>
            </thead>
            <tbody>
               <tr>
                <td align="center">
                  <div class="flag">
                    <img src="/flags/AU.png" style="width: 32px" />
                  </div>
                </td>

                <td class="text-right">£{{ Number(aud).toLocaleString() }}</td>
                <td class="text-right">
                  £{{ Number(audmtd).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(audlymtd).toLocaleString() }}
                </td>
                <td class="text-right" width="30px">
                  <!-- <el-alert
                    style="padding: 2px"
                    title=""
                    :closable="false"
                    effect="dark"
                    :type="
                      Math.sign((audmtd / audlymtd - 1) * 100) > 0
                        ? 'success'
                        : 'error'
                    "
                  >
                   {{ ((audmtd / audlymtd - 1) * 100).toFixed(2) }}%</el-alert
                  >-->
                </td>
              </tr>
               <tr>
                <td align="center">
                  <div class="flag">
                    <img src="/flags/CA.png" style="width: 32px" />
                  </div>
                </td>

                <td class="text-right">£{{ Number(cad).toLocaleString() }}</td>
                <td class="text-right">
                  £{{ Number(cadmtd).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(cadlymtd).toLocaleString() }}
                </td>
                <td class="text-right" width="30px">
                <!--  <el-alert
                    style="padding: 2px"
                    title=""
                    :closable="false"
                    effect="dark"
                    :type="
                      Math.sign((cadmtd /cadlymtd - 1) * 100) > 0
                        ? 'success'
                        : 'error'
                    "
                  >
                    {{ ((cadmtd / cadlymtd - 1) * 100).toFixed(2) }}%</el-alert
                  >-->
                </td>
              </tr>
              <tr>
                <td align="center">
                  <div class="flag">
                    <img src="/flags-svg/gb.svg" style="width: 32px" />
                  </div>
                </td>

                <td class="text-right">£{{ Number(uk).toLocaleString() }}</td>
                <td class="text-right">
                  £{{ Number(ukmtd).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(uklymtd).toLocaleString() }}
                </td>
                <td class="text-right" width="30px">
                  <el-alert
                    style="padding: 2px"
                    title=""
                    :closable="false"
                    effect="dark"
                    :type="
                      Math.sign((ukmtd / uklymtd - 1) * 100) > 0
                        ? 'success'
                        : 'error'
                    "
                  >
                    {{ ((ukmtd / uklymtd - 1) * 100).toFixed(2) }}%</el-alert
                  >
                </td>
              </tr>
              <tr>
                <td align="center">
                  <div class="flag">
                    <img src="/flags-svg/us.svg" style="width: 32px" />
                  </div>
                </td>

                <td class="text-right">£{{ Number(us).toLocaleString() }}</td>
                <td class="text-right">
                  £{{ Number(usmtd).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(uslymtd).toLocaleString() }}
                </td>
                <td class="text-right">
                      <el-alert
                    style="padding: 2px"
                    title=""
                    :closable="false"
                    effect="dark"
                    :type="
                      Math.sign((usmtd / uslymtd - 1) * 100) > 0
                        ? 'success'
                        : 'error'
                    "
                  >
                  {{ ((usmtd / uslymtd - 1) * 100).toFixed(2) }}% </el-alert>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <div class="flag">
                    <img src="/flags-svg/eu.svg" style="width: 32px" />
                  </div>
                </td>

                <td class="text-right">£{{ Number(eu).toLocaleString() }}</td>
                <td class="text-right">
                  £{{ Number(eumtd).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(eulymtd).toLocaleString() }}
                </td>
                <td class="text-right">
                      <el-alert
                    style="padding: 2px"
                    title=""
                    :closable="false"
                    effect="dark"
                    :type="
                      Math.sign((eumtd / eulymtd - 1) * 100) > 0
                        ? 'success'
                        : 'error'
                    "
                  >
                  {{ ((eumtd / eulymtd - 1) * 100).toFixed(2) }}%
                      </el-alert>
                </td>
              </tr>
              <tr class="success-row">
                <td style="text-align: center;">Total</td>

                <td class="text-right">
                  £{{ Number(uk + us + eu + cad + aud).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(ukmtd + usmtd + eumtd + cadmtd + audmtd).toLocaleString() }}
                </td>
                <td class="text-right">
                  £{{ Number(uklymtd + uslymtd + eulymtd).toLocaleString() }}
                </td>
                <td class="text-right">
                      <el-alert
                    style="padding: 2px"
                    title=""
                    :closable="false"
                    effect="dark"
                    :type="
                      Math.sign((ukmtd + usmtd + eumtd) / (uklymtd + uslymtd + eulymtd - 1) * 100) > 0
                        ? 'error'
                        : 'success'
                    "
                  >
                  {{
                    (
                      ((ukmtd + usmtd + eumtd) / (uklymtd + uslymtd + eulymtd) -
                        1) *
                      100
                    ).toFixed(2)
                  }}%</el-alert>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import { useQuery, useResult, useQueryLoading } from "@vue/apollo-composable";
import moment from "moment";
import Data from "./sales_agg.query.gql";

//import { reactive } from "vue";
export default {
  setup() {
    //yesterdays date
    const today = moment();
    const yesterday = today.subtract(1, "days");

    const startofmonth = moment().startOf("month").add(1, "days");
    console.log(startofmonth.format("YYYY-MM-DD"));
    const lastyearstart = moment()
      .startOf("month")
      .add(1, "days")
      .subtract(1, "years");
    const lastyeartd = moment().subtract(1, "days").subtract(1, "years");
    const yesterday_vars = {
      from: yesterday,
      to: today,
      mdate: startofmonth,
    };
    const mtd_vars = {
      from: startofmonth,
      to: yesterday,
    };
    const lastyear_vars = {
      from: lastyearstart,
      to: lastyeartd,
    };

    const { result: day } = useQuery(Data, yesterday_vars);
    const { result: mtd } = useQuery(Data, mtd_vars);
    const { result: ly } = useQuery(Data, lastyear_vars);
    const loading = useQueryLoading();
    const uk = useResult(
      day,
      null,
      (data) => data.uk_sales_aggregate.aggregate.sum.uk
    );
    const us = useResult(
      day,
      null,
      (data) => data.us_sales_aggregate.aggregate.sum.us
    );
    const eu = useResult(
      day,
      null,
      (data) => data.eu_sales_aggregate.aggregate.sum.eu
    );
        const cad = useResult(
      day,
      null,
      (data) => data.cad_sales_aggregate.aggregate.sum.cad
    );
    const aud = useResult(
      day,
      null,
      (data) => data.aud_sales_aggregate.aggregate.sum.aud
    );
    const ukmtd = useResult(
      mtd,
      null,
      (data) => data.uk_sales_aggregate.aggregate.sum.uk
    );
    const usmtd = useResult(
      mtd,
      null,
      (data) => data.us_sales_aggregate.aggregate.sum.us
    );
    const cadmtd = useResult(
      mtd,
      null,
      (data) => data.cad_sales_aggregate.aggregate.sum.cad
    );
        const audmtd = useResult(
      mtd,
      null,
      (data) => data.aud_sales_aggregate.aggregate.sum.aud
    );
        const eumtd = useResult(
      mtd,
      null,
      (data) => data.eu_sales_aggregate.aggregate.sum.eu
    );
    const uklymtd = useResult(
      ly,
      null,
      (data) => data.uk_sales_aggregate.aggregate.sum.uk
    );
    const uslymtd = useResult(
      ly,
      null,
      (data) => data.us_sales_aggregate.aggregate.sum.us
    );
    const eulymtd = useResult(
      ly,
      null,
      (data) => data.eu_sales_aggregate.aggregate.sum.eu
    );
       const cadlymtd = useResult(
      ly,
      null,
      (data) => data.cad_sales_aggregate.aggregate.sum.cad
    );
           const audlymtd = useResult(
      ly,
      null,
      (data) => data.aud_sales_aggregate.aggregate.sum.aud
    );

    return {
      yesterday,
      loading,
      uk,
      us,
      eu,
      cad,
      aud,
      ukmtd,
      usmtd,
      eumtd,
      cadmtd,
      audmtd,
      uklymtd,
      uslymtd,
      eulymtd,
      cadlymtd,
      audlymtd
    };
  },
};
</script>
<style scoped>
.text-right {
  text-align: right;
}
.success-row {
  padding: 10px;
  background: #e0e2e4;
  font-weight: bold;
  color: rgb(8, 0, 0);
}
</style>