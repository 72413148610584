<template>
<el-card v-if="!loading" class="box-card">
    <template #header>
    <div class="card-header">
      <h3>Web Users</h3>
      <span class="text-muted">By Month Source:GA</span>
    </div>
  </template>
  <apexchart
  
    v-if="!loading"
    width="100%"
    type="bar"
    :options="options"
    :series="series"
  ></apexchart>

</el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import Data from "../graphql/ga_users_month.query.gql";
import { reactive } from "vue";
export default {
  setup() {
         const { result, loading } = useQuery(Data);
    const options = reactive({
      chart: {
        id: "WebUsers",
        stacked: true
      },
      dataLabels: {
  enabled: true,
  enabledOnSeries: [0,1],
  formatter: function (val) {
      return val.toLocaleString()
  },
  textAnchor: 'middle',
  distributed: false,
  offsetX: 0,
  offsetY: 10,
  style: {
      fontSize: '12px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      colors: undefined
  },
   background: {
    enabled: true,
    foreColor: '#fff',
    padding: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#fff',
    opacity: 0.9,
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0.45
    }
  },
      },
           yaxis:{forceNiceScale:true, decimalsInFloat:false},
      xaxis: {
        categories: useResult(result, null, (data) =>
          data.ga_users_month.map((a) => a.year + "-" + a.month)
        ),
      },
      grid: {
        padding: {
          top: 20,
          bottom: 10,
          left: 10,
        },
      }
    });
      const series = reactive([
      {
        name: "users",
        data: useResult(result, null, (data) =>
          data.ga_users_month.map((a) => a.users)
        ),
      },
      {
        name: "new users",
        data: useResult(result, null, (data) =>
          data.ga_users_month.map((a) => a.new_users)
        ),
      },
    ]);
     return { loading, result, options, series };
  },
};
</script>