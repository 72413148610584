<template>
  <div>
    <nav class="navbar navbar-transparent navbar-absolute">
      <div class="container">
        <div class="navbar-header">
          <router-link class="navbar-brand" to="/"
            ><img :src="logo" alt="TEFL Org Logo" width="150"
          /></router-link>
        </div>
      </div>
    </nav>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
        data-color="green"
        data-image="background/background-5.png"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <el-container>
            <el-row>
              <el-col :span="5" :offset="9">
                <el-card data-background="color" data-color="red">
                  <div class="card-header">
                    <h3 class="card-title">Login</h3>
                  </div>
                  <div class="card-content">
                    <el-form ref="form" :model="form" label-width="120px">
                      <div
                        v-if="error"
                        @hide="hideError"
                        class="alert alert-danger"
                      >
                        <button
                          type="button"
                          class="close"
                          v-on:click="hideError()"
                        >
                          ×
                        </button>
                        <span>
                          <b> Error - </b> Your username or password is
                          incorrect</span
                        > </div>
                  <el-form-item label="Email">
    <el-input @focus="hideError()" placeholder="Email"  v-model="user.username"></el-input>
  </el-form-item>
                        
                               <el-form-item label="Password">
    <el-input @focus="hideError()"  type="password"        placeholder="Password" v-model="user.password"></el-input>
  </el-form-item>       
                       </el-form>
                 </div>
                  <div class="card-footer text-center">
                    <el-button @click="login(user)" type="primary" plain
                      >Login</el-button
                    >
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-container>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="copyright">
              &copy; Coded with
              <i class="fa fa-heart heart"></i> by
              <a href="https://www.tefl.org/" target="_blank">TEFL Org</a>.
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    logo: {
      type: String,
      default: "TEFL-Logo-White.svg",
    },
  },
  setup() {
    let activeTab = "employee";
    const error = ref(false);
    const store = useStore();
    const router = useRouter();
    const user = reactive({ username: "", password: "" });
    const hideError = () => (error.value = false);

    const login = (user) =>
      store
        .dispatch("auth/login", user)
        .then((d) => {
          console.log(d);
          router.push("/");
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
        });

    return {
      user,
      login,
      hideError,
      activeTab,
      error,
    };
  },
};
</script>
<style>
</style>