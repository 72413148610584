export default [

  {
    link: "/",
    name: "Dashboard",
    icon: "el-icon-data-board",
  },
  {
    link:"/",
    name: "Sales",
    icon: "el-icon-user",
    child: [
      { icon: "el-icon-s-custom",
        link: "/sales/country",
        name: "By Country",
      },
      { icon: "el-icon-collection-tag",
        link: "/sales/product",
        name: "By Product",
      },
      { icon: "el-icon-collection-tag",
      link: "/sales/value",
      name: "By Value",
    },
      { icon: "fab fa-stripe-s fa-lg fa-fw",
        link: "/sales/stripe",
        name: "Stripe ",
      },

      
      { icon: "fab fa-paypal fa-lg fa-fw",
        link: "/sales/paypal",
        name: "Paypal",
      }
    ],
  },
  
  {
    link:"/",
    name: "Analytics",
    icon: "el-icon-user",
    child: [
      { icon: "el-icon-s-custom",
        link: "/analytics/search",
        name: "Search Position",
      },
      { icon: "el-icon-collection-tag",
        link: "/user/tags",
        name: "Revenue",
      }
    ],
  }
]
