<template>
<div>
  <el-table
    v-loading="loading"
    :data="data"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-bind="$attrs"
       :row-class-name="rowClassName"
    :show-summary="showSummary"
    :summary-method="summary"
    style="width: 100%"
  >
    <slot name="columns">
      <el-table-column
      :stripe="true"
      border="true"
      :min-width="column.minWidth"
        :sortable="column.sortable ? 'custom' : false"
        v-for="column in columns"
    
        :key="column.prop"
        
        v-bind="column"
      >
        <template v-slot:row>

     
        </template>
      </el-table-column>
      <el-table-column v-if="showEdit" :min-width="150">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row.id, scope.row)"
            >Edit</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </slot>
  </el-table>
  <slot name="pagination" :page="page" :total="total">
    <el-pagination
    background
      
      :page-sizes="[10, 20, 30, 40,50,100,200]"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      v-model="page"
      :total="total"
      :loading="loading"
      :page-size="pageSize"
    :pager-count="10"
    current-page="1"
      layout="prev, pager, next, sizes,total"
    >
    </el-pagination>
 
  </slot>
  </div>
</template>
<script>
import { ref } from "vue";
import {useRoute,useRouter} from "vue-router"

import { format } from 'date-fns'
export default {
  name: "DataTable",
  inheritAttrs: false,
  emits: ["pagechanged"],
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
summary:{
  type:Function
},
rowClassName:{  type:Function},
    data: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: () => "",
    },

    total: {
      type: Number,
      default: () => 0,
    },
       pageSize: {
      type: Number,
      default: () => 10,
    },
    showEdit: {
      type: Boolean,
      default: () => true,
    },
       showSummary: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    let loading=false;
    //let total = ref(10);
    let route=useRoute().name;
    let router=useRouter();
    
    let page = ref(1);
   

    function handleSizeChange(val) {
      emit("pageSizeChanged", val);
    }
    function handleCurrentChange(val) {
      emit("pageCurrentChanged", val);
    }
      function handleEdit(val) {
 
      router.push('/'+route.toLowerCase()+'/'+val);
    
    }
    
    return { loading, page, handleSizeChange, handleCurrentChange,handleEdit,format,route };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>