<template>
  <el-card v-if="!loading" class="box-card" shadow="hover" >
    <template #header>
      <div class="card-header">
        <h3>Sales This Month</h3>
        <span class="text-muted">By day & 7 Day Average</span>
      </div>
    </template>
    <apexchart
      v-if="!loading"
      width="100%"
      height="300px"
      type="line"
      id="salese"
      :options="options"
      :series="series"
      
    ></apexchart>
  </el-card>
  
</template>
<script>
import { useQuery, useResult, useQueryLoading } from "@vue/apollo-composable";
import moment from "moment";
import { ma } from "moving-averages";
import Data from "./sales_query.gql";

import { reactive } from "vue";
export default {
  setup() {
    //const today = moment();
    const yesterday=moment().subtract(1,'days')
    const startofmonth = moment().startOf("month");
    const sales_vars = {
      from: startofmonth.clone().subtract(7, "days"),
      to: yesterday,
      mdate:startofmonth
    };
    const { result: sales } = useQuery(Data, sales_vars);

    const loading = useQueryLoading();
    const options = reactive({
      chart: {
        stacked: true,
        type: "line",
        toolbar: {
          show: true,
        },
      },
      tooltip: {
        shared: false,
        enabledOnSeries: [0, 1, 2, 3, 4],
      },
      yaxis: {  decimalsInFloat: false },
      xaxis: {
        type: "datetime",
        categories: useResult(sales, [], (data) =>
          data.eu_sales
            .filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            )
            .map((a) => a.date)
        ),
      },
    });
    const series = reactive([
      {
        name: "EU",
        type: "column",
        data: useResult(sales, [], (data) =>
          data.eu_sales
            .filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            )
            .map((a) => a.total.toFixed(2))
        ),
      },
      {
        name: "US",
        type: "column",
        data: useResult(sales, [], (data) =>
          data.us_sales
            .filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            )
            .map((a) => a.total.toFixed(2))
        ),
      },
      {
        name: "UK",
        type: "column",
        data: useResult(sales, [], (data) =>
          data.uk_sales
            .filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            )
            .map((a) => a.total.toFixed(2))
        ),
      },

      {
        name: "EU avg",
        type: "line",
        data: useResult(sales, [], (data) =>
          ma(
            data.eu_sales.map((a) => a.total),
            7
          ).filter((a) =>
          moment(a.date).isSameOrAfter(moment().startOf("month"))
          ).splice(0, data.eu_sales.filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            ).length)
        ),
      },
      {
        name: "US avg",
        type: "line",
         data: useResult(sales, [], (data) =>
          ma(
            data.us_sales.map((a) => a.total),
            7
          ).filter((a) =>
            moment(a.date).isSameOrAfter(moment().startOf("month"))
          ).splice(0, data.us_sales.filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            ).length)
        ),
      },
      {
        name: "UK avg",
        type: "line",
        data: useResult(sales, [], (data) =>
          ma(
            data.uk_sales.map((a) => a.total),
            7
          ).filter((a) =>
            moment(a.date).isSameOrAfter(moment().startOf("month"))
          ).splice(0, data.uk_sales.filter((a) =>
              moment(a.date).isSameOrAfter(moment().startOf("month"))
            ).length)
        ),
      }
        
    ]);

    return { loading, options, series };
  },
};
</script>
<style scoped>
html,body{
    zoom: 100%
    }
</style>