<template>
  <el-card v-if="!loading" class="box-card">
    <template #header>
      <div class="card-header">
        <h3>Sales by Month</h3>
        <span class="text-muted">By Month Source:Stripe & paypal card</span>
      </div>
    </template>
   
  
  {{g}}
  
    <apexchart
      v-if="!loading"
      width="80%"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
    
  </el-card>
</template>
<script>
import { useQuery, useResult } from "@vue/apollo-composable";
//import Sales from "../graphql/sales_month.gql";
import {reactive} from "vue";
import moment from "moment";

import filters from "../filters/filters"
import gql from 'graphql-tag'
export default {
  setup() {
      let yearsToGet=3 
 //let yearRanges=[];
let query=`query MyQuery {`;


//  year0:monthly_sales_totals(order_by: {date: desc}, where: {date: {_gte: "2021-01-01", _lte: "2021-12-31"}}) {
//    date
  //  total
 // }


for (let x=0;x<yearsToGet;x++){ 
    if (x===0){//this year  
//yearRanges[x]=[moment().startOf('year'),moment().endOf('year')]
query+=`y${moment().startOf('year').format('YYYY')}:monthly_sales_totals(order_by: {date: asc}, where: {date: {_gte: "${moment().startOf('year')}", _lte: "${moment().endOf('year')}"}}) {date total}`
  }else{
        //previous x years
//yearRanges[x]=[moment().startOf('year').subtract(x,'years'),moment().endOf('year').subtract(x,'years')];
 query+=`y${moment().startOf('year').subtract(x,'years').format('YYYY')}:monthly_sales_totals(order_by: {date: asc}, where: {date: {_gte: "${moment().startOf('year').subtract(x,'years')}", _lte: "${moment().endOf('year').subtract(x,'years')}"}}) {date total}`

    }

}
query+=`}`;
query=gql`${query}`;
let series = [ ];  

    const { result, loading } = useQuery(query);
   const g=useResult(result, null, (data) => {
     
  Object.entries(data).forEach(([key, value]) => {
      series.push({name:key,data:value.map(s =>s.total)})
 
});

    })


  //

//series.push({name:"",data:g.value['y2021']})
    const options = reactive({
      chart: {
        id: "users",
      
      },  plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              }
            }, colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
              '#f48024', '#69d2e7'
            ],
            
            dataLabels: {
              enabled: false,
              textAnchor: 'start',
              style: {
                colors: ['#000']
              },
              formatter: function (val) {
              
                return " £" + val.toLocaleString()
              },
              offsetX: -30,
              dropShadow: {
                enabled: false
              }
            },
            stroke: {
              width: 2,
             colors: ['transparent']
            },
      yaxis: { forceNiceScale: true, decimalsInFloat: false,title: {
                text: '£ Sales Total'
              } },
      xaxis: {
        categories: useResult(result, null, (data) =>
          data.y2020.map(a => moment(a.date).format('MMM'))),

      },
      grid: {
        padding: {
          top: 20,
          bottom: 10,
          left: 10,
        },
      },
    });
     

    return { loading, result, options, series, filters,g };
  },
};
</script>