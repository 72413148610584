import {
    provideApolloClient,
    useQuery
} from '@vue/apollo-composable'
import client from '../../gglclient'

import Login from '../../graphql/Login.query.gql'
const state = {
    status: '',
    hasLoadedOnce: false,
    loggedInUser: {}
}

const getters = {
    isAuthenticated: state => !!state.isAuthenticated,
    authStatus: state => state.status,
    getAuth: state => state
}

const actions = {
    login({
        commit
    }, user) {
        return new Promise((resolve,reject) => {
            provideApolloClient(client)(() => useQuery(Login, {
                username: user.username,
                password: user.password
            }, {})
            .onResult(res => {
                if (res.error)
                reject(res.error)
                else{
                localStorage.setItem('jwt_token', res.data.login.accessToken);
                commit('userLoggedIn', res.data.login)
                resolve()
            }
            }))
        })
    },
    logout() {
        return new Promise((resolve) => {
            localStorage.removeItem('jwt_token')
            localStorage.removeItem('vuex')
            client.clearStore()
            resolve()
        })
    }
}

const mutations = {
    userLoggedIn(state, user) {
        state.loggedInUser.firstname = user.firstname
        state.status = 'success'
        state.isAuthenticated = true
        state.hasLoadedOnce = true
    }

}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}